<template>
  <div class="alterPwd">
    <el-dialog
      title="修改密码"
      width="640px"
      :before-close="cancel"
      v-model="state.visiblePwd"
    >
      <div class="steps">
        <div class="activeSel" v-if="state.isActive === 0">
          <img src="@/assets/personImg/1-red.png" alt="">
          <span>验证账号</span>
          <img src="@/assets/personImg/right_gray.png" alt="">
        </div>
        <div class="activeSel2" v-if="state.isActive > 0">
          <img src="@/assets/personImg/complete.png" alt="">
          <span>验证账号</span>
          <img src="@/assets/personImg/right_red.png" alt="">
        </div>
         <div class="activeSel2" v-if="state.isActive === 1">
          <img src="@/assets/personImg/2-red.png" alt="">
          <span>变更信息</span>
          <img src="@/assets/personImg/right_gray.png" alt="">
        </div>
         <div class="activeSel2" v-if="state.isActive === 2">
          <img src="@/assets/personImg/complete.png" alt="">
          <span>变更信息</span>
          <img src="@/assets/personImg/right_red.png" alt="">
        </div>
         <div class="activeSel" v-if="state.isActive === 0">
          <img src="@/assets/personImg/2-gray.png" alt="">
          <span>变更信息</span>
          <img src="@/assets/personImg/right_gray.png" alt="">
        </div>
        <div class="activeSel2" v-if="state.isActive === 2">
          <img src="@/assets/personImg/3-red.png" alt="">
          <span>完成</span>
        </div>
        <div class="activeSel" v-else>
          <img src="@/assets/personImg/3-gray.png" alt="">
          <span>完成</span>
        </div>
      </div>
      <!-- <span>这是一段信息</span> -->
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="editForm"
        label-width="120px"
        class="demo-ruleForm"
        v-show="state.isActive === 0"
      >
        <el-form-item
          label="当前绑定手机："
          prop="tel"
        >
          <span>{{ ruleForm.tel }}</span
          >
        </el-form-item>
        <el-form-item
          label="手机验证码："
          prop="telCode"
          style="white-space:nowrap"
        >
          <el-input
            size="medium"
            style="width: 55%"
            placeholder="请输入手机验证码"
            v-model="ruleForm.telCode"
          ></el-input>
          <el-button v-if='state.isShowSend' @click="sendCode" :disabled="sendLoading" class="sendBtn">发送验证码</el-button>
          <el-button v-else class="sendBtn" disabled>重发验证码({{state.clock}})</el-button>
        </el-form-item>
      </el-form>
      <el-form
        ref="editFormNew"
        :model="ruleFormNew"
        :rules="rulesNew"
        label-width="120px"
        class="demo-ruleForm"
        v-show="state.isActive === 1"
      >
        <el-form-item label="新密码：" prop="newPwd">
          <el-input
            show-password
            size="medium"
            placeholder="请输入新密码"
            v-model="ruleFormNew.newPwd"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码：" prop="reNewPwd">
          <el-input
            show-password
            size="medium"
            placeholder="请确认新密码"
            v-model="ruleFormNew.reNewPwd"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-form
        label-width="120px"
        class="change-phone"
        v-show="state.isActive === 2"
      >
        您的手机号已成功修改！
      </el-form>
      <template #footer>
        <span class="dialog-footer" v-if="state.isActive === 0">
          <el-button type="primary" size="medium" @click="next" :disabled="state.isDisabled">下一步</el-button>
        </span>
        <span class="dialog-footer" v-else-if="state.isActive === 1">
          <el-button type="primary" size="medium" @click="save" :disabled="state.isDisabled">确 定</el-button>
          <el-button size="medium" @click="cancel">取 消</el-button>
        </span>
        <span class="dialog-footer" v-else>
          <el-button type="primary" size="medium" @click="down">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, watch, defineComponent, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import axios from '@/utils/request'
import { geetestAction } from '@/utils/index'

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  setup (props, ctx) {
    // vuex
    const store = useStore()
    const stateVuex = store.state
    const ruleForm = reactive({
      security_key: '',
      telCode: '',
      tel: computed(() => {
        return (
          stateVuex.phone.substring(0, 3) +
          '****' +
          stateVuex.phone.substring(7)
        )
      })
    })
    const ruleFormNew = reactive({
      newPwd: '',
      reNewPwd: ''
    })
    // 定义弹窗状态
    const state = reactive({
      visiblePwd: false,
      generate_key: '',
      isShowSend: true,
      isActive: 0,
      clock: 60,
      isDisabled: false
    })
    watch(
      () => props.modelValue,
      (newValue) => {
        state.visiblePwd = newValue
      }
    )

    // 点击锁
    const codeLock = ref(false)

    // 发送验证码
    const sendLoading = ref(false)
    const sendCode = () => {
      if (codeLock.value) return
      codeLock.value = true
      sendLoading.value = true
      geetestAction().then(result => {
        axios.post('/api/v1/verification/send_sms/', {
          phone: stateVuex.phone,
          action: 'change_pass',
          ...result
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((res) => {
          if (res.error_code === 0) {
            ElMessage.success('发送成功')
            ruleForm.security_key = res.data.security_key
            state.isShowSend = false
            const test = setInterval(() => {
              state.clock--
              if (state.clock === 0) {
                clearInterval(test)
                state.isShowSend = true
                state.clock = 60
              }
            }, 1000)
          } else {
            ElMessage.error(res.data.error_msg)
          }
        }).finally(() => {
          sendLoading.value = false
        })
      }).catch(() => {
        sendLoading.value = false
      }).finally(() => {
        codeLock.value = false
      })
    }
    // 点击下一步按钮
    const editForm = ref(null)
    const next = () => {
      editForm.value.validate((valid) => {
        if (valid) {
          state.isDisabled = true
          axios
            .post(
              '/api/v1/verification/verify_sms/',
              {
                security_key: ruleForm.security_key,
                code: ruleForm.telCode,
                phone: stateVuex.phone
              })
            .then((res) => {
              if (res.error_code === 0) {
                editForm.value.resetFields()
                state.generate_key = res.data.generate_key
                state.isActive = 1
              } else {
                state.isDisabled = false
                ElMessage.error('验证码不正确')
              }
            }).finally(() => {
              state.isDisabled = false
            })
        }
      })
    }
    // 点击确定按钮
    const editFormNew = ref(null)
    const save = () => {
      editFormNew.value.validate((valid) => {
        if (valid) {
          state.isDisabled = true
          axios.post('/api/v1/customer/change_password/', {
            generate_key: state.generate_key,
            password: ruleFormNew.reNewPwd
          }).then(res => {
            if (res.error_code === 0) {
              state.isActive = 2
            } else {
              ElMessage.error(res.error_msg)
              state.isDisabled = false
            }
          }).fianlly(() => {
            state.isDisabled = false
          })
        }
      })
    }

    // 完成后点击确定
    const down = () => {
      ctx.emit('update:modelValue', false)
      cancel()
    }
    // 点击取消按钮
    const cancel = () => {
      ctx.emit('update:modelValue', false)
      editForm.value.resetFields()
      state.isDisabled = false
      state.isActive = 0
      state.isShowSend = true
      state.clock = 0
    }
    // 表单校验
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (ruleFormNew.checkPass !== '') {
          editForm.value.validateField('checkPass')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== ruleFormNew.newPwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    const rules = {
      telCode: [
        { required: true, message: '请输入手机验证码', trigger: 'blur' },
        { min: 4, max: 4, message: '长度是4个字符', trigger: 'blur' }
      ]
    }
    const rulesNew = {
      newPwd: [
        { required: true, message: '请输入新密码', trigger: 'blur' },
        { min: 6, max: 15, message: '新密码由字母、符号、数字组成，长度6-15位', trigger: 'blur' },
        { validator: validatePass, trigger: 'blur' }
      ],
      reNewPwd: [
        { required: true, message: '请确认新密码', trigger: 'blur' },
        { validator: validatePass2, trigger: 'blur' }
      ]
    }
    return {
      save,
      down,
      state,
      cancel,
      editForm,
      rules,
      ruleForm,
      next,
      rulesNew,
      ruleFormNew,
      editFormNew,
      sendCode,
      sendLoading
    }
  }
})
</script>
<style scoped lang='scss'>
:deep().el-form-item {
  margin-bottom: 15px !important;
}
:deep().el-form-item__content {
  text-align: start;
}
.alterPwd {
  :deep().el-dialog {
    border-radius: 4px;
  }
  .dialog-footer {
    button {
      width: 80px;
    }
  }
  .change-phone {
    margin-top: 32px;
    span {
      font-size: 14px;
      color: #242426;
    }
  }
  .steps{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
    .activeSel{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // width: 33%;
      margin-right: 12px;
      img:nth-of-type(1){
        width: 40px;
        height: 40px;
        margin-right:12px ;
      }
      span{
        margin-right:12px ;
        font-size: 14px;
        color: #242426;
        white-space: nowrap;
        display: block;
      }
      img:nth-of-type(2){
        width: 36px;
        height: 3px;
      }
    }
    .activeSel:nth-of-type(3){
      margin-right: 0;
    }
    .activeSel2{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // width: 33%;
      margin-right: 12px;
      img:nth-of-type(1){
        width: 40px;
        height: 40px;
        margin-right:12px ;
      }
      span{
        margin-right:12px ;
        font-size: 14px;
        color: #84868C;
        white-space: nowrap;
        display: block;
      }
      img:nth-of-type(2){
        width: 36px;
        height: 3px;
      }
    }
    .activeSel2:nth-of-type(3){
      margin-right: 0;
    }
  }
}
.sendBtn {
  margin-left: 10px;
  font-size: 14px;
  width: 127px;
  height: 36px;
  min-height: 36px;
  padding: 0;
  border-radius: 2px;
}
.sendBtn2 {
  margin-left: 10px;
  font-size: 14px;
  height: 36px;
  min-height: 36px;
  border-radius: 2px;
}
  :deep().el-dialog__title{
    font-size: 16px;
    color: #242426;
    font-weight: 600;
  }
  :deep().el-dialog__header{
    text-align: left;
    padding: 20px 20px 19px;
    border-bottom: 1px solid #E9ECF2;
  }
   :deep().el-dialog__body{
    padding: 20px 111px;
  }
  :deep().el-dialog__title{
    font-size: 16px;
    color: #242426;
    font-weight: 600;
  }
  :deep().el-button-red{
    background: #F04848;
    span{
      color: white;
    }
  }
</style>
