<template>
  <div class="alterPhone">
    <el-dialog
      title="修改手机号"
      width="640px"
      :before-close="cancel"
      v-model="state.visiblePhone"
    >
      <!-- <span>这是一段信息</span> -->
      <!-- 这里写步骤条的----------------------------------- -->
      <div class="steps">
        <div class="activeSel" v-if="state.isActive === 0">
          <img src="@/assets/personImg/1-red.png" alt="">
          <span>验证账号</span>
          <img src="@/assets/personImg/right_gray.png" alt="">
        </div>
        <div class="activeSel2" v-if="state.isActive > 0">
          <img src="@/assets/personImg/complete.png" alt="">
          <span>验证账号</span>
          <img src="@/assets/personImg/right_red.png" alt="">
        </div>
         <div class="activeSel2" v-if="state.isActive === 1">
          <img src="@/assets/personImg/2-red.png" alt="">
          <span>变更信息</span>
          <img src="@/assets/personImg/right_gray.png" alt="">
        </div>
         <div class="activeSel2" v-if="state.isActive === 2">
          <img src="@/assets/personImg/complete.png" alt="">
          <span>变更信息</span>
          <img src="@/assets/personImg/right_red.png" alt="">
        </div>
         <div class="activeSel" v-if="state.isActive === 0">
          <img src="@/assets/personImg/2-gray.png" alt="">
          <span>变更信息</span>
          <img src="@/assets/personImg/right_gray.png" alt="">
        </div>
        <div class="activeSel2" v-if="state.isActive === 2">
          <img src="@/assets/personImg/3-red.png" alt="">
          <span>完成</span>
        </div>
        <div class="activeSel" v-else>
          <img src="@/assets/personImg/3-gray.png" alt="">
          <span>完成</span>
        </div>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="editForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="当前绑定手机："
          v-if="state.isActive === 0"
          prop="tel"
        >
          <span>{{ ruleForm.tel }}</span>
        </el-form-item>
        <el-form-item
          label="验证码："
          v-if="state.isActive === 0"
          prop="telCode"
        >
          <el-input
            style="width: 54%"
            size="medium"
            placeholder="请输入手机验证码"
            v-model="ruleForm.telCode"
          ></el-input>
          <el-button v-if='!state.clock' @click="sendCode" :disabled="sendLoading" class="sendBtn">发送验证码</el-button>
          <el-button v-else class="sendBtn" disabled>重发验证码({{state.clock}})</el-button>
        </el-form-item>
      </el-form>
      <el-form
        :model="ruleFormNew"
        :rules="rulesNew"
        ref="editFormNew"
        label-width="120px"
        class="demo-ruleForm"
        v-if="state.isActive === 1"
      >
        <el-form-item label="新手机号：" prop="newPhone">
          <el-input
            size="medium"
            placeholder="请输入新手机号"
            v-model="ruleFormNew.newPhone"
          ></el-input>

        </el-form-item>
        <el-form-item label="验证码：" prop="newCode">
          <el-input
            style="width:54%;"
            size="medium"
            placeholder="请输入验证码"
            v-model="ruleFormNew.newCode"
          ></el-input>
          <el-button v-if='!state.clock2' @click="sendCode2" :disabled="sendLoading2" class="sendBtn">发送验证码</el-button>
          <el-button v-else class="sendBtn" disabled>重发验证码({{state.clock2}})</el-button>
        </el-form-item>
        <!-- <el-form-item label="确认手机号：" prop="reNewPwd">
          <el-input
            size="mini"
            placeholder="请确认手机号"
            v-model="ruleFormNew.reNewPwd"
          ></el-input>
        </el-form-item> -->
      </el-form>
      <span class="contSpan" v-if="state.isActive === 2">您的手机号已修改成功</span>
      <template #footer>
        <span class="dialog-footer" v-if="state.isActive <= 1">
          <el-button type="primary" size="medium" @click="next">下一步</el-button>
        </span>
        <span class="dialog-footer" v-else>
          <el-button class="el-button-red" size="medium" @click="cancel">确 认</el-button>
          <!-- <el-button size="mini" @click="cancel">取 消</el-button> -->
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, watch, defineComponent, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from '@/utils/request'
import { ElMessage } from 'element-plus'
import { geetestAction } from '@/utils/index'

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  setup (props, ctx) {
    const router = useRouter()
    // vuex
    const store = useStore()
    const { dispatch } = reactive(useStore())
    const stateVuex = store.state

    // 点击锁
    const codeLock = ref(false)

    // 发送验证码
    const sendLoading = ref(false)
    const sendCode = () => {
      if (codeLock.value) return
      codeLock.value = true
      sendLoading.value = true
      geetestAction().then(result => {
        axios.post('/api/v1/verification/send_sms/', {
          phone: stateVuex.phone,
          action: 'change_mobile',
          ...result
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((res) => {
          if (res.error_code === 0) {
            ElMessage.success('发送成功')
            state.clock = 60
            ruleForm.security_key = res.data.security_key
            // 倒计时
            const test = setInterval(() => {
              state.clock--
              if (state.clock === 0) {
                clearInterval(test)
              }
            }, 1000)
          } else {
            ElMessage.error(res.data.error_msg)
          }
        }).finally(() => {
          sendLoading.value = false
        })
      }).catch(() => {
        sendLoading.value = false
      }).finally(() => {
        codeLock.value = false
      })
    }

    const sendLoading2 = ref(false)
    const sendCode2 = () => {
      if (codeLock.value) return
      codeLock.value = true
      sendLoading2.value = true

      geetestAction().then(result => {
        axios.post('/api/v1/verification/send_sms/', {
          phone: ruleFormNew.newPhone,
          action: 'bind_mobile',
          ...result
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((res) => {
          if (res.error_code === 0) {
            ElMessage.success('发送成功')
            state.clock2 = 60
            ruleForm.security_key = res.data.security_key
            // 倒计时
            state.isShowSend2 = false
            const test2 = setInterval(() => {
              state.clock2--
              if (state.clock2 === 0) {
                clearInterval(test2)
                state.isShowSend2 = true
              }
            }, 1000)
          } else {
            ElMessage.error(res.data.error_msg)
          }
        }).finally(() => {
          sendLoading2.value = false
        })
      }).catch(() => {
        sendLoading2.value = false
      }).finally(() => {
        codeLock.value = false
      })
    }
    const ruleForm = reactive({
      telCode: '',
      security_key: '',
      tel: computed(() => {
        return (
          stateVuex.phone.substring(0, 3) +
          '****' +
          stateVuex.phone.substring(7)
        )
      })
    })
    const ruleFormNew = reactive({
      newPhone: '',
      // reNewPwd: ''
      newCode: ''
    })
    // 定义弹窗状态
    const state = reactive({
      visiblePhone: false,
      isShowNext: false,
      generate_key: '',
      isActive: 0,
      clock: 0,
      clock2: 0,
      isShowSend2: true
    })
    watch(
      () => props.modelValue,
      (newValue) => {
        state.visiblePhone = newValue
      }
    )
    // 点击下一步按钮
    const editFormNew = ref(null)
    const next = () => {
      if (state.isActive === 0) {
        editForm.value.validate((valid) => {
          if (valid) {
            axios
              .post(
                '/api/v1/verification/verify_sms/',
                {
                  security_key: ruleForm.security_key,
                  code: ruleForm.telCode,
                  phone: stateVuex.phone
                },
                {
                  header: {
                    '  Content-Type': 'application/json	'
                  }
                }
              )
              .then((res) => {
                if (res.error_code === 0) {
                  state.isShowNext = true
                  state.isActive = 1
                  state.generate_key = res.data.generate_key
                } else {
                  ElMessage.error('验证码不正确')
                }
              })
          }
        })
      } else if (state.isActive === 1) {
        // 第二次点击下一步，走验证新手机号方法========
        editFormNew.value.validate((valid) => {
          if (valid) {
            axios
              .post(
                '/api/v1/customer/change_mobile',
                {
                  security_key: ruleForm.security_key,
                  generate_key: state.generate_key,
                  code: ruleFormNew.newCode,
                  phone: ruleFormNew.newPhone
                },
                {
                  header: {
                    '  Content-Type': 'application/json	'
                  }
                }
              ).then(res => {
                if (res.error_code === 0) {
                  ElMessage.success('修改成功')
                  state.isActive = 2
                  // ctx.commit('setPhone', ruleFormNew.newPhone)
                  store.dispatch('changePhone', ruleFormNew.newPhone)
                  const localInfo = JSON.parse(localStorage.getItem('DATAZHI-USER'))
                  localInfo.phone = ruleFormNew.phone
                  localStorage.setItem('DATAZHI-USER', JSON.stringify(localInfo))
                  setTimeout(() => {
                    dispatch('quit', false).then(msg => {
                      router.push('/login')
                      ElMessage({
                        message: msg,
                        type: 'success',
                        duration: 2 * 1000
                      })
                    })
                  }, 2000)
                } else {
                  ElMessage.error(res.error_msg)
                }
              })
          }
        })
      }
    }

    // 清空表单
    const editForm = ref(null)
    // 点击确定按钮
    const save = () => {
      editFormNew.value.validate((valid) => {
        if (valid) {
          axios
            .post(
              '/api/v1/customer/change_mobile',
              {
                security_key: ruleForm.security_key,
                generate_key: state.generate_key,
                code: ruleForm.newCode,
                phone: ruleFormNew.newPhone
              },
              {
                header: {
                  '  Content-Type': 'application/json	'
                }
              }
            ).then(res => {
              if (res.error_code === 0) {
                ElMessage.success('修改成功')
              } else {
                ElMessage.error(res.error_msg)
              }
            })
        }
      })
    }

    // 点击取消按钮
    const cancel = () => {
      ruleForm.telCode = ''
      ctx.emit('update:modelValue', false)
      editForm.value.resetFields()
      state.isActive = 0
      state.isShowNext = false
    }
    // 表单校验
    const rules = {
      telCode: [
        { required: true, message: '请输入手机验证码', trigger: 'blur' },
        { min: 4, max: 4, message: '长度是4个字符', trigger: 'blur' }
      ]
    }
    const rulesNew = {
      newPhone: [
        { required: true, message: '请输入新手机号', trigger: 'blur' }
      ],
      newCode: [
        { required: true, message: '请输入验证码', trigger: 'blur' }
      ]
    }
    return {
      save,
      state,
      cancel,
      editForm,
      rules,
      ruleForm,
      next,
      rulesNew,
      ruleFormNew,
      editFormNew,
      sendCode,
      sendCode2,
      sendLoading,
      sendLoading2
    }
  }
})
</script>
<style scoped lang='scss'>
.alterPhone .dialog-footer {
  button {
    width: 80px;
  }
}
:deep().el-form-item {
  margin-bottom: 15px !important;
}
:deep().el-form-item__content {
  text-align: start;
}
.alterPhone{
  .contSpan{
    font-size: 14px;
    color: #242426;
  }
  .steps{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
    .activeSel{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // width: 33%;
      margin-right: 12px;
      img:nth-of-type(1){
        width: 40px;
        height: 40px;
        margin-right:12px ;
      }
      span{
        margin-right:12px ;
        font-size: 14px;
        color: #242426;
        white-space: nowrap;
        display: block;
      }
      img:nth-of-type(2){
        width: 36px;
        height: 3px;
      }
    }
    .activeSel:nth-of-type(3){
      margin-right: 0;
    }
      .activeSel2{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // width: 33%;
      margin-right: 12px;
      img:nth-of-type(1){
        width: 40px;
        height: 40px;
        margin-right:12px ;
      }
      span{
        margin-right:12px ;
        font-size: 14px;
        color: #84868C;
        white-space: nowrap;
        display: block;
      }
      img:nth-of-type(2){
        width: 36px;
        height: 3px;
      }
    }
    .activeSel2:nth-of-type(3){
      margin-right: 0;
    }
  }
  :deep().el-dialog {
    border-radius: 4px;
  }
  :deep().el-dialog__header{
    text-align: left;
    padding: 20px 20px 19px;
    border-bottom: 1px solid #E9ECF2;
  }
  :deep().el-dialog__body{
    padding: 24px 111px;
  }
  :deep().el-dialog__title{
    font-size: 16px;
    color: #242426;
    font-weight: 600;
  }
  //element按钮颜色样式
  :deep().el-button-red{
    background: #F04848;
    span{
      color: white;
    }
  }
  //form表单字体
  :deep().el-form-item__label{
    font-size: 14px;
    color: #242426;
  }
  :deep().el-form-item__content{
    span{
      font-size: 14px;
    }
  }
  .sendBtn {
    margin-left: 10px;
    font-size: 14px;
    width: 127px;
    height: 36px;
    min-height: 36px;
    padding: 0;
    margin-left: 10px;
  }
}
</style>
